import React, { useEffect, useState } from "react";

// External
import styled from "styled-components";
import { Element } from "react-scroll";
import { useLocation } from "@reach/router";

// Internal
import SplashText from "../../components/SplashText";
import { breakpoints, fontFamily, spacing } from "../../../theme";
import { ALL_RECOMMENDATIONS } from "../../utils/constants/recommendations";
import Button from "../../components/form/Button";
import Image from "../../components/common/Image";

// Styles
const Section = styled(Element)`
  // height: 100vh;
  padding: 20px 0px 60px;
  background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 0 150px;
  }
`;

const Heading = styled.h1`
  font-family: ${fontFamily.extraBold};
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -1%;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }
`;

const ReviewCardsListContainer = styled.div`
  margin-top: ${spacing(9)};
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 120px;
  }
`;
const SkillCard = styled.div`
  background: ${props => props.bgColor};
  border-radius: 24px;
  width: 80%;
  margin: 0 auto 12px;
  padding: 27px;
  // width: 335px;
  // height: 237px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 42px 112px;
    width: 27%;
    max-width: 503px;
    margin: 0;
  }
`;
const SkillCardIcon = styled.div`
  padding: 16px 0;

  & svg {
    transform: scale(1.5);
    padding-left: 16px;
    margin-bottom: 32px;
  }
`;
const SkillCardHeading = styled.div`
  margin: 4px 0;
  font-size: 20px;
  font-family: ${fontFamily.bold};
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 36px;
  }
`;
const SkillCardDescription = styled.div`
  margin-bottom: 12px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
    width: 98%;
    margin-top: 8px;
  }
`;

const ReviewsList = styled.div`
  text-align: center;

  width: 94%;
  margin: 0 auto;
  margin-top: 2em;
`;
const ReviewCard = styled.div`
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 20px 16px 32px;
  margin-block: 8px;
  text-align: left;

  max-width: 800px;
  margin: 8px auto;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 16px auto;
    padding: 24px 32px 32px;
  }
`;
const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const ClientImage = styled(Image)`
  border-radius: 50%;
  width: 52px;
`;
const ReviewNameSection = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 1rem;
  margin-top: -0.1rem;
`;
const ClientName = styled.h6`
  margin: 0;
  font-size: 1rem;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 1.2rem;
  }
`;
const ClientPosition = styled.span`
  font-size: 0.7rem;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 0.8rem;
  }
`;
const ReviewTextContainer = styled.div`
  //   margin-left: calc(52px + 1rem);
  //   margin-left: 1rem;
  margin-top: 1rem;
  font-weight: 500;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0.2rem;
    margin-left: calc(52px + 1rem);
  }
`;
const ReviewText = styled.q`
  font-style: italic;

  letter-spacing: -0.003em;
  line-height: 28px;
  margin-top: 1.16em;
  font-size: 14px;
  // font-size: 21px;
  margin-bottom: -0.46em;
  color: rgba(41, 41, 41, 1);

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 1.56em;
    line-height: 32px;
    margin-top: 2em;
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 4em 0;
`;

const ReviewsSection = () => {
  const [index, setIndex] = useState(3);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/testimonials") {
      setIndex(ALL_RECOMMENDATIONS.length);
      setCanLoadMore(false);
    }
  }, [pathname]);

  const handleViewMore = () => {
    if (canLoadMore) {
      if (index + 3 >= ALL_RECOMMENDATIONS.length) {
        setCanLoadMore(false);
      }
      setIndex(index => index + 3);
    }
  };

  return (
    <Section name="testimonials" as="section">
      <Heading>
        <SplashText variant="peach">Testimonials</SplashText>
      </Heading>
      <ReviewCardsListContainer>
        <ReviewsList>
          {ALL_RECOMMENDATIONS.slice(0, index).map((review, index) => (
            <ReviewCard key={index}>
              <ReviewHeader>
                <ClientImage src={review.profilePic} alt={review.name} />
                <ReviewNameSection>
                  <ClientName>{review.name}</ClientName>
                  <ClientPosition>
                    {review.position} - {review.company}
                  </ClientPosition>
                </ReviewNameSection>
              </ReviewHeader>
              <ReviewTextContainer>
                <ReviewText>{review.text}</ReviewText>
              </ReviewTextContainer>
            </ReviewCard>
          ))}
        </ReviewsList>
        {canLoadMore && (
          <ButtonContainer>
            <Button onClick={handleViewMore}>View More</Button>
          </ButtonContainer>
        )}
      </ReviewCardsListContainer>
    </Section>
  );
};

export default ReviewsSection;
