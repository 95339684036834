export const RECOMMENDATION_VAIBHAV_BHARDWAJ = {
  name: "Vaibhav Bhardwaj",
  position: "CTO",
  company: "Credit Culture",
  profilePic: "people/credit-culture__vaibhav.jpeg",
  text: `I got the chance to briefly work with Parmeet and I must say that I enjoyed every single second of that. He is one of the few people (I've worked with) who have a zeal to learn more, can quickly adapt to new technology, is a fast learner, highly productive and more important is honest and humble.

    He has got a very pleasing personality and is very presentable.
    
    Parmeet is self disciplined, can manage and plan his time very efficiently and can deliver tasks on time.
    
    I would like to recommend him as a Full Stack Developer (JavaScript) who can deliver polished front end applications on ReactJs that are backed by equally capable backend developed in NodeJs (deployed on AWS Cloud)
    
    People like him are an asset to any team.`,
};

export const RECOMMENDATION_ELISA_FIORENZANI = {
  name: "Elisa Fiorenzani",
  position: "UX/UI Designer",
  company: "Condexo",
  profilePic: "people/condexo__elisa.jpeg",
  text: `It was a pleasure to work with Parmeet, an honest and reliable Dev!`,
};

export const RECOMMENDATION_SARABJOT_SINGH = {
  name: "Sarabjot Singh",
  position: "Full Stack Developer",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__sarabjot-singh.jpeg",
  text: `Parmeet is a creative developer with fantastic eye for unique and innovative designs in addition to his great problem solving skills and zeal to be acquainted with new inclusions to JS.
  He is an excellent team player who can outshine independently as well.`,
};
export const RECOMMENDATION_PRANAV_BANSAL = {
  name: "Pranav Bansal",
  position: "Software Engineer",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__pranav.jpeg",
  text: `There is no better colleague than Parmeet. He is one of the most dedicated professionals I’ve worked with and is willing to put that extra help whenever you need it. His expertise as a developer is considerable, and it helped our team come up with more efficient solutions on different projects. He is a great asset to our company.`,
};
export const RECOMMENDATION_NIKHIL_GANGWAR = {
  name: "Nikhil Singh Gangwar",
  position: "Lead Developer",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__nikhil.jpeg",
  text: `Parmeet was my junior at classic informatics and we have worked closely on numerous task alongside. His attention to detail and eagerness to learn sets him apart from anyone that I have worked with. 

  Parmeet’s ability to handle any challenge thrown at him with utmost level of calmness and sincerity makes him an asset for organisation. When it comes to front end I have never seen someone so excited about the things they are developing, Parmeet is keen to make a noticeable impact on anything he picks up. 
  
  He was an remarkable colleague and I still miss working with him. As a lead, Parmeet earns my highest recommendation.`,
};

export const RECOMMENDATION_NAVEEN_DHARNI = {
  name: "Naveen Dharni",
  position: "Senior Associate",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__naveen.jpeg",
  text: `Parmeet is an excellent developer and a great friend, he is really good in React and has been a real gem to our last company. His technical skills are impressive as per his experience. He always makes sure to meet all the deadlines within a given time frame & with the top standards. He is hardworking & dedicated in his work. It is a pleasure and honor to recommend Parmeet to anyone who wants to hire him.`,
};

export const RECOMMENDATION_SUNNY_TYAGI = {
  name: "Sunny Tyagi",
  position: "Full Stack Developer",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__sunny.jpeg",
  text: `Parmeet is a good and technical sound is his work. I worked with him on 2 projects and he developed the project proficiently on frontend and backend. I hope to get work opportunities with him in future so we can connect the world together for the betterment.`,
};

export const RECOMMENDATION_PURNIMA_LAMBA = {
  name: "Purnima Lamba",
  position: "Senior Associate",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__purnima.jpeg",
  text: `I am pleased to write a letter of recommendation for Parmeet Singh. I highly recommend Parmeet Singh to your organization.

    I have known Parmeet Singh for the past 3 years as he was working with me in the same project at Classic informatics. During our time together, his collaboration and leadership skills were truely impressive, and his UI & design work on a project won glowing reviews from peers and client both.
    
    Parmeet Singh is well equipped to grow from challenges that he is presented with. His strong computer programming skills prepare him beautifully for your company. I strongly endorse making Parmeet Singh a member of your team.`,
};

export const RECOMMENDATION_NEHA_KAPUR = {
  name: "Neha Kapur",
  position: "Project Manager",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__neha.jpeg",
  text: `I really enjoyed working with Parmeet. Highly recommended for React-based web development projects with the rich user interfaces.

    He is eager to learn and improve in all areas. He has excellent communication and technical skills. He is hard-working, ensures deadlines are duly met, and never compromises with product quality.`,
};

export const RECOMMENDATION_PRASHANT_SAXENA = {
  name: "Prashant Saxena",
  position: "Lead Developer",
  company: "Classic Informatics",
  profilePic: "people/classic-informatics__prashant.jpeg",
  text: `He is a good coder and has a humble attitude. His work quality is really good. I got a chance to work with him and we both learnt a lot.`,
};

export const RECOMMENDATION_ASHISH_MEHRA = {
  name: "Ashish Mehra",
  position: "Full Stack Developer",
  company: "Credit Culture",
  profilePic: "people/credit-culture__ashish.jpeg",
  text: `I've worked with Parmeet for short period of time. He is very skillful frontend developer. He don't limit himself to one thing always love to explore new things and share with others.`,
};

export const RECOMMENDATION_DEEPANSH_BHARGAVA = {
  name: "Deepansh Bhardwaj",
  position: "Full Stack Developer",
  company: "Credit Culture",
  profilePic: "people/credit-culture__deepansh.jpeg",
  text: `I worked with Parmeet in Credit Culture. He possesses great abilities in UI/UX. He always brings new ideas to the table and shows great dedication to work. He'll be a good addition to any team.`,
};

export const RECOMMENDATION_DAVINDER_SINGH = {
  name: "Davinder Singh",
  position: "CEO & Co-Founder",
  company: "Harmindra International",
  profilePic: "people/harmindra-international__davinder-singh.jpeg",
  text: `It was our pleasure to work with Parmeet. He worked on creating the online identity of our business. He got not just the development of the website done but got the copywriting and product photography work done as well. The website created by him is fast and responsive. It has helped us land many new international clients and increase our sales by a whopping 25%. I would strongly recommend hiring him.`,
};

export const ALL_RECOMMENDATIONS = [
  RECOMMENDATION_VAIBHAV_BHARDWAJ,
  RECOMMENDATION_NEHA_KAPUR,
  RECOMMENDATION_DAVINDER_SINGH,
  RECOMMENDATION_ELISA_FIORENZANI,
  RECOMMENDATION_NIKHIL_GANGWAR,
  RECOMMENDATION_NAVEEN_DHARNI,
  RECOMMENDATION_PURNIMA_LAMBA,
  RECOMMENDATION_PRANAV_BANSAL,
  RECOMMENDATION_SUNNY_TYAGI,
  RECOMMENDATION_DEEPANSH_BHARGAVA,
  RECOMMENDATION_ASHISH_MEHRA,
  RECOMMENDATION_PRASHANT_SAXENA,
  RECOMMENDATION_SARABJOT_SINGH,
];
